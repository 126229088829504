import React, { useState, useEffect } from 'react';

const PurchaseHistory = ({ purchaseDetails, onClose }) => {
    console.log(purchaseDetails);
    const handleClose = () => {
        onClose();
    };

    // Function to format the date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} `;
    };

    return (
        <div id="add" className="modal fade show" role="dialog" style={{ display: "block" }}>
            <div style={{ borderRadius: "20px" }} className="modal-dialog modal-lg overflow-hidden">
                <div className="modal-content">
                    <div style={{ backgroundColor: "#00509D", color: "white" }} className="modal-header">
                        <h5 className="modal-title">Outward History</h5>
                        <button type="button" className="button_details " onClick={handleClose}>
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                    <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                        <div className="modalmain">
                            <div>
                                <p><strong>Created At:</strong> {formatDate(purchaseDetails.createdAt)}</p>
                                <p><strong>Invoice Number:</strong> {purchaseDetails.invoiceNumber}</p>
                                <p><strong>Payment Mode:</strong> {purchaseDetails.paymentMode}</p>
                                <p><strong>Purchase Amount:</strong> {purchaseDetails.purchaseAmount}</p>
                                <p><strong>Purchase Date:</strong>  {formatDate(purchaseDetails.purchaseDate)}</p>
                                <p><strong>Purchase Description:</strong> {purchaseDetails.purchaseDescription}</p>
                                <p><strong>Purchased Approved By:</strong> {purchaseDetails.purchasedApprovedBy}</p>
                                <p><strong>Purchased By:</strong> {purchaseDetails.purchasedBy}</p>
                                <p><strong>Remark:</strong> {purchaseDetails.remark}</p>
                                <p><strong>Updated At:</strong> {purchaseDetails.updatedAt}</p>
                                <p><strong>Vendor Address:</strong> {purchaseDetails.vendorAddress}</p>
                                <p><strong>Vendor Code:</strong> {purchaseDetails.vendorCode}</p>
                                <p><strong>Vendor Name:</strong> {purchaseDetails.vendorName}</p>
                                <p><strong>Selected Service Products:</strong> {purchaseDetails.purchaseItems && JSON.parse(purchaseDetails.purchaseItems).map(product => product.name).join(', ')}</p>
                            </div>
                            <div style={{ width: "15vw", height: "30vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <img style={{ width: "100%", height: "100%", objectFit: "contain" }} src={`${process.env.REACT_APP_LOCAL_URL}/uploads/purchase_photo/${purchaseDetails.upload1}`} alt="Purchase Upload" />
                            </div>
                        </div>
                    </div>
                    <div style={{ backgroundColor: "#00509D", color: "white" }} className="modal-footer">
                        <button type="button" className="button_details" onClick={handleClose}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PurchaseHistory;
