import React, { useState, useEffect } from "react";
import axios from "axios";
import AddPurchase from "./AddPurchase";
import PurchaseDesc from "./PurchaseDesc";
import EditPurchase from './EditPurchase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from "../../components/sidebar/Sidebar";
import SearchBar from "../../components/sidebar/SearchBar";
import { ThreeDots } from 'react-loader-spinner'; // Spinner
import DeleteConfirmationModal from "../DeleteConfirmationModal";

function PurchaseList({ handleLogout, username }) {
    const [isLoading, setIsLoading] = useState(false);
    const [purchases, setPurchases] = useState([]);
    const [filteredPurchases, setFilteredPurchases] = useState([]);
    const [selectedPurchase, setSelectedPurchase] = useState(null);
    const [showPurchaseDetails, setShowPurchaseDetails] = useState(false);
    const [isAddPurchaseModalOpen, setIsAddPurchaseModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editPurchase, setEditPurchase] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [deletePurchase, setDeletePurchase] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        fetchPurchases();
    }, []);

    useEffect(() => {
        filterPurchasesByDate();
    }, [purchases, startDate, endDate]);

    const fetchPurchases = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/purchases`);
            const sortedPurchases = response.data.sort((a, b) => new Date(b.purchaseDate) - new Date(a.purchaseDate));
            setPurchases(sortedPurchases);
        } catch (error) {
            console.error("Error fetching purchases:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const filterPurchasesByDate = () => {
        if (startDate && endDate) {
            const filtered = purchases.filter(purchase => {
                const purchaseDate = new Date(purchase.purchaseDate).setHours(0, 0, 0, 0);
                const start = new Date(startDate).setHours(0, 0, 0, 0);
                const end = new Date(endDate).setHours(23, 59, 59, 999); // Include the entire end date
                return purchaseDate >= start && purchaseDate <= end;
            });
            setFilteredPurchases(filtered);
        } else {
            setFilteredPurchases(purchases);
        }
    };

    const handleAddPurchase = () => {
        setIsAddPurchaseModalOpen(true);
    };

    const handleClosePurchaseModal = () => {
        setIsAddPurchaseModalOpen(false);
        setIsEditModalOpen(false);
    };

    const handlePurchaseDetails = (purchase) => {
        setSelectedPurchase(purchase);
        setShowPurchaseDetails(true);
    };

    const handleEditPurchaseClick = (purchase) => {
        setEditPurchase(purchase);
        setSelectedPurchase(purchase);
        setIsEditModalOpen(true);
    };

    const handleEditPurchaseClose = () => {
        setSelectedPurchase(null);
    };

    const handleBackToTable = () => {
        setSelectedPurchase(null);
        setShowPurchaseDetails(false);
    };

    const handleDeletePurchase = (purchase) => {
        setDeletePurchase(purchase);
        setIsDeleteModalOpen(true);
    };

    const handleUpdatePurchaseLists = () => {
        toast.success('Data uploaded successfully');
        fetchPurchases();
    };

    const handleUpdatePurchase = async (updatedPurchase) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/purchases/${updatedPurchase.id}`, updatedPurchase);
            console.log("Purchase updated:", response.data);
            const updatedPurchaseList = purchases.map(purchase => (purchase.id === updatedPurchase.id ? response.data : purchase));
            const sortedPurchases = updatedPurchaseList.sort((a, b) => new Date(b.purchaseDate) - new Date(a.purchaseDate));
            setPurchases(sortedPurchases);
        } catch (error) {
            console.error("Error updating purchase:", error);
        }
    };

    const handleDeleteConfirmation = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/purchases/${deletePurchase.id}`);
            const deletedPurchase = { ...deletePurchase, reason: deleteReason };
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedPurchase);
            setPurchases(prevPurchases => prevPurchases.filter(purchase => purchase.id !== deletePurchase.id));
            setIsDeleteModalOpen(false);
            console.log("Purchase deleted successfully");
        } catch (error) {
            console.error("Error deleting purchase:", error);
        }
    };

    const handleUpdatePurchaseList = async (newPurchase) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/purchases`, newPurchase);
            const newPurchaseEntry = response.data;
            setPurchases(prevPurchases => [newPurchaseEntry, ...prevPurchases]);
            toast.success("Purchase entry added successfully");
        } catch (error) {
            console.error("Error adding purchase:", error);
        }
    };

    // Function to format the date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} `;
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredPurchases.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className='d-flex w-100 h-100 bg-white'>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    {showPurchaseDetails && selectedPurchase ? (
                        <PurchaseDesc
                            purchase={selectedPurchase}
                            onClose={handleBackToTable}
                        />) : (
                        <div className="row">
                            <div className="col-xl-12">
                                <div style={{ borderRadius: "20px", border: "1px solid #003459" }} className='overflow-hidden'>
                                    <div style={{ background: "rgb(33,131,128)", background: "#00509d " }} className="row no-gutters align-items-center p-3">
                                        <div className="col">
                                            <div className="text-xs font-weight-bold text-white text-uppercase d-flex align-items-center justify-content-between" style={{ fontSize: '1.5rem' }}>
                                                <div className="nunito text-white">Purchase List</div>
                                                <div className=" d-flex gap-3">
                                                    <div className="d-flex align-items-center justify-content-center gap-2">
                                                        <label className='nunito text-white p-0 m-0'>Filter:-</label>
                                                        <input
                                                            type="date"
                                                            value={startDate}
                                                            onChange={(e) => setStartDate(e.target.value)}
                                                            className="form-control button_details datepicker"
                                                        />
                                                        <input
                                                            type="date"
                                                            value={endDate}
                                                            onChange={(e) => setEndDate(e.target.value)}
                                                            className="form-control button_details datepicker"
                                                        />
                                                    </div>
                                                </div>
                                                <button onClick={handleAddPurchase} className="button_details"><i className="fa fa-plus"></i> Add New Purchase</button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='m-0 p-0' />
                                    <div className=''>
                                        <div className="card-body">
                                            <div className="" style={{ maxHeight: "610px", overflowY: "auto" }}>
                                                {isLoading ? (
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <ThreeDots color="#00509d" height={80} width={80} />
                                                    </div>
                                                ) : (
                                                    <table className="table table-bordered" style={{ width: "100%" }}>
                                                        <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                            <tr>
                                                                <th>Vendor Name</th>
                                                                <th>Vendor Code</th>
                                                                <th>Purchase Date</th>
                                                                <th>Purchase Amount</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <style>
                                                                {`.hyperlink:hover {color: blue;}`}
                                                            </style>
                                                            {currentItems.length === 0 ? (
                                                                <tr>
                                                                    <td colSpan="12" className="text-center">No Inquiry Entries.</td>
                                                                </tr>
                                                            ) : (
                                                                currentItems.map((purchase, index) => (
                                                                    <tr key={index}>
                                                                        <td className='hyperlink' style={{ cursor: "pointer" }} onClick={() => handlePurchaseDetails(purchase)}>{purchase.vendorName}</td>
                                                                        <td>{purchase.vendorCode}</td>
                                                                        <td>{formatDate(purchase.purchaseDate)}</td>
                                                                        <td>{purchase.purchaseAmount}</td>
                                                                        <td>
                                                                            <div className="d-flex align-items-center justify-content-start gap-3 z-99">
                                                                                <div className="btn-group">
                                                                                    <button
                                                                                        className="button_action"
                                                                                        type="button"
                                                                                        data-toggle="dropdown"
                                                                                        aria-haspopup="true"
                                                                                        aria-expanded="false"
                                                                                    >
                                                                                        <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                                                                    </button>
                                                                                    <div className="dropdown-menu actionmenu">

                                                                                        <a className="dropdown-item" href="#" onClick={() => handleEditPurchaseClick(purchase)}>
                                                                                            <i className="fas fa-edit"></i> Edit
                                                                                        </a>
                                                                                        <a className="dropdown-item" href="#" onClick={() => handleDeletePurchase(purchase)}>
                                                                                            <i className="fa fa-trash"></i> Delete
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            )}
                                                        </tbody>
                                                    </table>
                                                )}
                                            </div>
                                            <ul className="pagination mt-2">
                                                <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                    <button className="page-link" onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                                </li>
                                                {Array.from({ length: Math.ceil(filteredPurchases.length / itemsPerPage) }, (_, i) => (
                                                    <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                        <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
                                                    </li>
                                                ))}
                                                <li className={`page-item ${currentPage === Math.ceil(filteredPurchases.length / itemsPerPage) && 'disabled'}`}>
                                                    <button className="page-link" onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(filteredPurchases.length / itemsPerPage)}>Next</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {showPurchaseDetails && selectedPurchase && (
                                        <PurchaseDesc
                                            purchase={selectedPurchase}
                                            onClose={handleBackToTable}
                                        />
                                    )}
                                    {selectedPurchase && !showPurchaseDetails && (
                                        <EditPurchase purchase={selectedPurchase} onClose={handleEditPurchaseClose} onUpdate={handleUpdatePurchase} />
                                    )}
                                    {isAddPurchaseModalOpen && <AddPurchase onClose={handleClosePurchaseModal} onUpdate={handleUpdatePurchaseList} />}
                                    <DeleteConfirmationModal
                                        isOpen={isDeleteModalOpen}
                                        itemName={deletePurchase ? deletePurchase.vendorName : ""}
                                        onDelete={handleDeleteConfirmation}
                                        onClose={() => setIsDeleteModalOpen(false)}
                                        deleteReason={deleteReason}
                                        setDeleteReason={setDeleteReason}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PurchaseList;

