import React, { useState, useEffect } from "react";
import axios from "axios";
import NextInquiry from "./NextInquiry";
import { toast, ToastContainer } from 'react-toastify'; // Import toast components
import 'react-toastify/dist/ReactToastify.css';

const InquiryDesc = ({ inquiry, onClose }) => {
    const [inquiryHistory, setInquiryHistory] = useState([]);
    const [isNextModalOpen, setIsNextModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {
        const fetchInquiryHistory = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_LOCAL_URL}/api/inquiries/history/${inquiry.id}`
                );
                setInquiryHistory(response.data);
            } catch (error) {
                console.error("Error fetching inquiry history:", error);
            }
        };

        fetchInquiryHistory();
    }, [inquiry]);


    const handleNextInquiry = () => {
        setIsNextModalOpen(true);
    };

    const handleCloseNextModal = () => {
        setIsNextModalOpen(false);
    };

    const updateStatus = async (status) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/inquiry_status/${inquiry.id}`, { status });
            console.log('Status data updated:', response.data);
            toast.success('updated successfully'); // Display toast notification
            // Optionally, you can update the local state or fetch the inquiry details again to reflect the changes
        } catch (error) {
            toast.error('Error updating status');
            console.error('Error updating status:', error);
        }
    };

    const handleConvertedClick = () => {
        updateStatus('converted');
    };

    const handleUnconvertedClick = () => {
        updateStatus('unconverted');
    };
    const handleupdatedesc = () => {
        toast.success('updated successfully');
    }

    // Function to format the date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} `;
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = inquiryHistory.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div>
            <div className="card-body p-4 bg-white rounded shadow-sm">
                <div className="row">

                    <div className="col-md-9 d-flex  justify-content-between px-3">
                        <div>
                            <h2 style={{ color: "#00509d" }} className="title-detail fw-bolder font-bold m-0">
                                {inquiry.customerName}
                            </h2>
                            <h6 style={{ color: "#00509d" }} className="title-detail fw-bolder font-bold m-0">
                                Call Attend Executive Name: {inquiry.callAttendExecutiveName}
                            </h6>
                            <small>Created By - {inquiry.username || "N/A"}</small>
                        </div>
                        <div>
                            <p className="m-0">
                                <i class="fa fa-envelope" aria-hidden="true"></i> <span> Email: {inquiry.emailId || "N/A"}</span>
                            </p>
                            <p className="m-0">
                                <i class="fa fa-phone"></i> <span> Phone: {inquiry.mobileNo || "N/A"}</span>
                            </p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="p-2 barcode-inner d-flex flex-column gap-1">
                            <div className="d-flex gap-2 align-items-center justify-content-center">
                                <button style={{ whiteSpace: "nowrap" }} onClick={onClose} className="button_edit">
                                    <i className="fa fa-arrow-left"></i>   Back
                                </button>
                                <button style={{ whiteSpace: "nowrap" }} onClick={handleNextInquiry} className="button_edit">
                                    <i className="fa fa-edit"></i>  Next Inquiry
                                </button>
                            </div>
                            <div className="d-flex gap-2 align-items-center justify-content-center">
                                <button style={{ whiteSpace: "nowrap" }} onClick={handleConvertedClick} className="button_edit">
                                    <i className="fa fa-arrow-left"></i>   Converted
                                </button>
                                <button style={{ whiteSpace: "nowrap" }} onClick={handleUnconvertedClick} className="button_edit">
                                    <i className="fa fa-edit"></i>  Unconverted
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a
                                    style={{color:"grey"}}
                                    className="nav-link button-link active show"
                                    id="details-tab"
                                    data-toggle="tab"
                                    href="#details"
                                    role="tab"
                                    aria-controls="details"
                                    aria-selected="true"
                                >
                                    Inquiry Details
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    style={{color:"grey"}}
                                    className="nav-link button-link"
                                    id="history-tab"
                                    data-toggle="tab"
                                    href="#history"
                                    role="tab"
                                    aria-controls="history"
                                    aria-selected="true"
                                >
                                    Inquiry history
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div
                                className="tab-pane fade active show"
                                id="details"
                                role="tabpanel"
                                aria-labelledby="details-tab"
                            >
                                <div className="row">
                                    <div className="col-md-12 d-flex" style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto", overflowX: "hidden" }}>
                                        <table className="table table-bordered table-hover" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td >
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Customer Name:</span> {inquiry.customerName || "-"}</p>
                                                    </td>

                                                    <td >
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Call Attend Executive Name:</span> {inquiry.callAttendExecutiveName || "-"}</p>
                                                    </td>
                                                </tr>
                                                
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Email:</span> {inquiry.emailId || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Mobile No:</span>  {inquiry.mobileNo || "-"}</p>
                                                    </td>

                                                </tr>
                                                
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Call Date: </span>  {formatDate(inquiry.callDate)  || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Next Call Date: </span>  {formatDate(inquiry.date) || "-"}</p>
                                                    </td>
                                                </tr>
                                                
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Inquiry Description: </span> {inquiry.description || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Not Listed Product/Service: </span> {inquiry.notListedProductService || "-"}</p>
                                                    </td>
                                                </tr>
                                                
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Selected Service Products: </span>
                                                         {inquiry.inquiryProductService ? (
                                                            <ul className="mb-0">
                                                                {JSON.parse(inquiry.inquiryProductService).map(product => (
                                                                    <li key={product.id}>{product.name}</li>
                                                                ))}
                                                            </ul>
                                                        ) : (
                                                            <p className="mb-0 text-dark"><span className="fw-bolder text-black" ></span>-</p>
                                                        )}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Total Inquiry Amount: </span> {inquiry.amount || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Status: </span> {inquiry.status || "-"}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/* Inquiry History */}
                            <div className="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Calling Date</th>
                                                    <th>Description</th>
                                                    <th>Next Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItems.map((entry) => (
                                                    <tr key={entry.event_id}>
                                                        
                                                        <td> {formatDate(entry.callingDate)}</td>
                                                        <td>{entry.description}</td>
                                                        <td> {formatDate(entry.nextDate)}</td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {/* Pagination */}
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                            </li>
                                            {Array.from({ length: Math.ceil(inquiryHistory.length / itemsPerPage) || 1 }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(inquiryHistory.length / itemsPerPage) && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isNextModalOpen && <NextInquiry
                onupdate={handleupdatedesc}
                inquiry={inquiry}
                onClose={handleCloseNextModal}
            />}
        </div>
    );
};

export default InquiryDesc;

































