import React, { useState, useEffect } from "react";
import axios from "axios";
import EditVendor from "./EditVendor";
import myImage from '../../images/brand_images/default_employee.jpg';
import PurchaseHistory from "./PurchaseHistory";
import InwardHistory from "../ClientMaster/InwardHistory";
import OutwardHistory from "../ClientMaster/OutwardHistory";

const VendorDesc = ({ vendor, onClose }) => {
    const [inwardHistory, setInwardHistory] = useState([]);
    const [outwardHistory, setOutwardHistory] = useState([]);
    const [purchaseHistory, setPurchaseHistory] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedinwardEntry, setSelectedinwardEntry] = useState(null);
    const [selectedOutwardEntry, setSelectedOutwardEntry] = useState(null);
    const [selectedPurchaseEntry, setSelectedPurchaseEntry] = useState(null);
    const [purchaseModalOpen, setpurchaseModalOpen] = useState(false);
    const [inwardModalOpen, setinwardModalOpen] = useState(false);
    const [outwardModalOpen, setoutwardModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);


    // Action Modal Open 
    // purchaseHistory
    const [purchaseHistoryModal, setPurchaseHistoryModal] = useState(false);
    const [purchaseHistoryDetails, setPurchaseHistoryDetails] = useState(null);

    // InwardHistory
    const [inwardHistoryModal, setInwardHistoryModal] = useState(false);
    const [inwardHistoryDetails, setInwardHistoryDetails] = useState(null);
    // OutwardHistory
    const [outwardHistoryModal, setOutwardHistoryModal] = useState(false);
    const [outwardHistoryDetails, setOutwardHistoryDetails] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            try {

                const inwardResponse = await axios.get(
                    `${process.env.REACT_APP_LOCAL_URL}/api/inward/vendor/${vendor.id}`
                );
                setInwardHistory(inwardResponse.data);

                const outwardResponse = await axios.get(
                    `${process.env.REACT_APP_LOCAL_URL}/api/outward/vendor/${vendor.id}`
                );
                setOutwardHistory(outwardResponse.data);

                const purchaseResponse = await axios.get(
                    `${process.env.REACT_APP_LOCAL_URL}/api/purchase/vendor/${vendor.id}`
                );
                setPurchaseHistory(purchaseResponse.data);

            } catch (error) {
                console.error("Error fetching history:", error);
            }
        };
        fetchData();
    }, [vendor.id]);


    const handleViewoutwardClick = (entry) => {
        setSelectedOutwardEntry(entry);
        setoutwardModalOpen(true);
    };
    const handleViewClick = (entry) => {
        setSelectedinwardEntry(entry);
        setinwardModalOpen(true);
    };
    const handleViewPurchaseClick = (entry) => {
        setSelectedPurchaseEntry(entry)
        setpurchaseModalOpen(true);
    };

    const closeModal = () => {
        setIsEditModalOpen(false);
        setpurchaseModalOpen(false);
        setinwardModalOpen(false);
        setoutwardModalOpen(false);
        setSelectedinwardEntry(null);
        setSelectedOutwardEntry(null);
        setSelectedPurchaseEntry(null);
    };

    const handleEditVendor = () => {
        setIsEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItemsInward = inwardHistory.slice(indexOfFirstItem, indexOfLastItem);
    const currentItemsOutward = outwardHistory.slice(indexOfFirstItem, indexOfLastItem);
    const currentItemspurchase = purchaseHistory.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const selectedServices = JSON.parse(vendor.selectedServices || '[]').map(service => service.name).join(', ');
    // total purchase Amount 
    const totalPurchaseAmount = purchaseHistory.reduce((total, purchase) => total + purchase.purchaseAmount, 0);

    // Download Button 
    const handleDownload = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/generate-pdf/vendor/${vendor.id}`, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${vendor.vendorName}-details.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error downloading PDF:", error);
        }
    };


    // Add Sales History Modal 
    const handlePurchaseHistory = (entry) => {
        setPurchaseHistoryDetails(entry)
        setPurchaseHistoryModal(true);
    };
    const handleClosePurchaseHistory = () => {
        setPurchaseHistoryModal(false);
    };

    // Add Inward History Modal 
    const handleInwardHistory = (entry) => {
        setInwardHistoryDetails(entry)
        setInwardHistoryModal(true);
    };
    const handleCloseInwardHistory = () => {
        setInwardHistoryModal(false);
    }

    // Add Outward History Modal 
    const handleOutwardHistory = (entry) => {
        setOutwardHistoryDetails(entry)
        setOutwardHistoryModal(true);
    };
    const handleCloseOutwardHistory = () => {
        setOutwardHistoryModal(false);
    }

    // Function to format the date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} `;
    };

    return (
        <div>
            <div className="card-body p-4 bg-white rounded shadow-sm">
                <div className="row">

                    <div className="col-md-9 d-flex  justify-content-between px-3">
                        <div>
                            <h2 style={{ color: "#00509d" }} className="title-detail fw-bolder font-bold m-0">
                                {vendor.vendorName}
                            </h2>
                            <h6 style={{ color: "#00509d" }} className="title-detail fw-bolder font-bold m-0">
                                Vendor Code: {vendor.vendorCode}
                            </h6>
                            <small>Created By - {vendor.username || "-"}</small>
                        </div>
                        <div>
                            <p className="m-0">
                                <i class="fa fa-envelope" aria-hidden="true"></i> <span> Email: {vendor.emailId1 || "-"}</span>
                            </p>
                            <p className="m-0">
                                <i class="fa fa-phone"></i> <span> Phone: {vendor.mobileNo1 || "-"}</span>
                            </p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="p-2 barcode-inner d-flex flex-column gap-1">
                            <div className="d-flex gap-2 align-items-center justify-content-center">
                                <button style={{ whiteSpace: "nowrap" }} onClick={onClose} className="button_edit">
                                    <i className="fa fa-arrow-left"></i>   Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a
                                    style={{ color: "grey" }}
                                    className="nav-link active show"
                                    id="details-tab"
                                    data-toggle="tab"
                                    href="#details"
                                    role="tab"
                                    aria-controls="details"
                                    aria-selected="true"
                                >
                                    Vendor Details
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    style={{ color: "grey" }}
                                    className="nav-link"
                                    id="purchase-tab"
                                    data-toggle="tab"
                                    href="#purchase"
                                    role="tab"
                                    aria-controls="purchase"
                                    aria-selected="false"
                                >
                                    Purchase
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    style={{ color: "grey" }}
                                    className="nav-link"
                                    id="inward-tab"
                                    data-toggle="tab"
                                    href="#inward"
                                    role="tab"
                                    aria-controls="inward"
                                    aria-selected="false"
                                >
                                    Inward
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    style={{ color: "grey" }}
                                    className="nav-link"
                                    id="outward-tab"
                                    data-toggle="tab"
                                    href="#outward"
                                    role="tab"
                                    aria-controls="outward"
                                    aria-selected="false"
                                >
                                    Outward
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div
                                className="tab-pane fade active show"
                                id="details"
                                role="tabpanel"
                                aria-labelledby="details-tab"
                            >
                                <div className="row">
                                    <div className="col-md-9" style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto", overflowX: "hidden" }}>
                                        <table className="table table-bordered table-hover" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td >
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Vendor Name:</span> {vendor.vendorName || "-"}</p>
                                                    </td>

                                                    <td >
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Vendor Code:</span> {vendor.vendorCode || "-"}</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Address:</span> {vendor.address || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >City:</span>  {vendor.city || "-"}</p>
                                                    </td>

                                                </tr>

                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >State: </span> {vendor.state || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Pincode: </span> {vendor.pincode || "-"}</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Contact Person 1: </span> {vendor.contactPersonName1 || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Contact Person 2: </span> {vendor.contactPersonName2 || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Email 1: </span> {vendor.emailId1 || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Email 2: </span> {vendor.emailId2 || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Mobile No 1: </span> {vendor.mobileNo1 || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Mobile No 2: </span> {vendor.mobileNo2 || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Mobile No 3: </span> {vendor.mobileNo3 || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >GST No: </span> {vendor.gstNo || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >PAN No: </span> {vendor.panNo || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Remark: </span> {vendor.remark || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Selected Services: </span> {selectedServices || "-"}</p>
                                                    </td>

                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-3 pt-2 text-center">
                                        {/* <img
                                            src={`${process.env.REACT_APP_LOCAL_URL}/uploads/vendor/${vendor.upload1}`}
                                            style={{ width: "200px" }}
                                            
                                        /> */}
                                        <img
                                            src={vendor.upload1
                                                ? `${process.env.REACT_APP_LOCAL_URL}/uploads/vendor/${vendor.upload1}`
                                                : myImage}
                                            style={{ width: "90px" }}
                                            className="employee-image"
                                        />
                                        <div className=" py-5">
                                            Total Purchase Amount :- {totalPurchaseAmount}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Purchase history */}
                            <div className="tab-pane fade" id="purchase" role="tabpanel" aria-labelledby="purchase-tab">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Vendor Name</th>
                                                    <th>Purchase Item</th>
                                                    <th>Purchase Date</th>
                                                    <th>Purchased By</th>
                                                    <th>Purchase Amount</th>
                                                    <th>Payment Mode</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItemspurchase.map((entry) => (
                                                    <tr key={entry.id}>
                                                        <td>{entry.vendorName}</td>
                                                        <td>{entry.purchaseItems && JSON.parse(entry.purchaseItems).map(product => product.name).join(', ')}</td>
                                                        <td> {formatDate(entry.purchaseDate)}</td>
                                                        <td>{entry.purchasedBy}</td>
                                                        <td>{entry.purchaseAmount}</td>
                                                        <td>{entry.paymentMode}</td>
                                                        <td><button className="btn btn-outline-info" onClick={() => handlePurchaseHistory(entry)}>View</button></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
                                            </li>
                                            {Array.from({ length: Math.ceil(purchaseHistory.length / itemsPerPage) || 1 }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                                    <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(purchaseHistory.length / itemsPerPage) ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {selectedPurchaseEntry && (
                                <div
                                    isOpen={purchaseModalOpen}
                                    onRequestClose={closeModal}
                                    className="modal-body custom-modal"
                                    style={{ position: "absolute" }}
                                >
                                    {/* Content for Inward Modal */}
                                    <div className="container-fluid incustom-modal" style={{ overflowY: "auto" }}>
                                        <div className="main-container" style={{ height: "100%" }}>
                                            <div className="modalnav">
                                                <h2>Purchase Details</h2>
                                            </div>
                                            <div className="modalmain">
                                                <div>
                                                    <div>
                                                        <p><strong>Created At:</strong>  {formatDate(selectedPurchaseEntry.createdAt)}</p>
                                                        <p><strong>Invoice Number:</strong> {selectedPurchaseEntry.invoiceNumber}</p>
                                                        <p><strong>Payment Mode:</strong> {selectedPurchaseEntry.paymentMode}</p>
                                                        <p><strong>Purchase Amount:</strong> {selectedPurchaseEntry.purchaseAmount}</p>
                                                        <p><strong>Purchase Date:</strong>  {formatDate(selectedPurchaseEntry.purchaseDate)}</p>
                                                        <p><strong>Purchase Description:</strong> {selectedPurchaseEntry.purchaseDescription}</p>
                                                        <p><strong>Purchased Approved By:</strong> {selectedPurchaseEntry.purchasedApprovedBy}</p>
                                                        <p><strong>Purchased By:</strong> {selectedPurchaseEntry.purchasedBy}</p>
                                                        <p><strong>Remark:</strong> {selectedPurchaseEntry.remark}</p>
                                                        <p><strong>Updated At:</strong> {selectedPurchaseEntry.updatedAt}</p>
                                                        <p><strong>Vendor Address:</strong> {selectedPurchaseEntry.vendorAddress}</p>
                                                        <p><strong>Vendor Code:</strong> {selectedPurchaseEntry.vendorCode}</p>
                                                        <p><strong>Vendor Name:</strong> {selectedPurchaseEntry.vendorName}</p>
                                                        <p><strong>Selected Service Products:</strong> {selectedPurchaseEntry.purchaseItems && JSON.parse(selectedPurchaseEntry.purchaseItems).map(product => product.name).join(', ')}</p>
                                                    </div>
                                                </div>
                                                <div style={{ width: "15vw", height: "30vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <img style={{ width: "100%", height: "100%", objectFit: "contain" }} src={`${process.env.REACT_APP_LOCAL_URL}/uploads/purchase_photo/${selectedPurchaseEntry.upload1}`} alt="Purchase Upload" />
                                                </div>
                                            </div>
                                            <div className="modalfooter">
                                                <button className="btn btn-secondary" onClick={closeModal}>Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* Inward history */}
                            <div className="tab-pane fade" id="inward" role="tabpanel" aria-labelledby="inward-tab">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Vendor Name</th>
                                                    <th>Consignment Date</th>
                                                    <th>Courier Company</th>
                                                    <th>Received By </th>
                                                    <th>Received Date</th>
                                                    <th>Consignment Subject</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItemsInward.map((entry) => (
                                                    <tr key={entry.id}>
                                                        <td>{entry.entityName}</td>
                                                        <td> {formatDate(entry.consignmentdate)}</td>
                                                        <td>{entry.courierCompany}</td>
                                                        <td>{entry.ename}</td>
                                                        <td> {formatDate(entry.receiptDate)}</td>
                                                        <td>{entry.consignmentType}</td>
                                                        <td><button className="btn btn-outline-info" onClick={() => handleInwardHistory(entry)}>View</button></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
                                            </li>
                                            {Array.from({ length: Math.ceil(inwardHistory.length / itemsPerPage) || 1 }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                                    <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(inwardHistory.length / itemsPerPage) ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {selectedinwardEntry && (
                                <div
                                    isOpen={inwardModalOpen}
                                    onRequestClose={closeModal} className="modal-body custom-modal"
                                    style={{ position: "absolute" }}
                                >
                                    {/* Content for Inward Modal */}
                                    <div className="container-fluid incustom-modal" style={{ overflowY: "auto" }}>
                                        <div className="main-container" style={{ height: "100%" }}>
                                            <div className="modalnav">
                                                <h2>Inward Details</h2>
                                            </div>
                                            <div className="modalmain">
                                                <div>
                                                    <p><strong>Vendor Name:</strong> {selectedinwardEntry.entityName}</p>
                                                    <p><strong>Vendor Code:</strong> {selectedinwardEntry.code}</p>
                                                    <p><strong>Address:</strong> {selectedinwardEntry.address}</p>
                                                    <p><strong>Sate:</strong> {selectedinwardEntry.state}</p>
                                                    <p><strong>City:</strong> {selectedinwardEntry.city}</p>
                                                    <p><strong>Pincode:</strong> {selectedinwardEntry.pincode}</p>
                                                    <p><strong>Courier Company:</strong> {selectedinwardEntry.courierCompany}</p>
                                                    <p><strong>Consignment No:</strong> {selectedinwardEntry.consignmentNo}</p>

                                                    <p><strong>Consignment Date:</strong>  {formatDate(selectedinwardEntry.consignmentdate)}</p>
                                                    <p><strong>Ship From:</strong> {selectedinwardEntry.shipfromaddress}</p>
                                                    <p><strong>Ship To:</strong> {selectedinwardEntry.shipTo}</p>
                                                    <p><strong>Received Date:</strong> {formatDate(selectedinwardEntry.receiptDate)}</p>
                                                    <p><strong>Received By:</strong> {selectedinwardEntry.ename}</p>
                                                    <p><strong>Consignment Type:</strong> {selectedinwardEntry.consignmentType}</p>
                                                    <p><strong>Remark:</strong> {selectedinwardEntry.remark}</p>
                                                    <p><strong>Invoice:</strong> {selectedinwardEntry.invoice || "-"}</p>
                                                </div>
                                                <div style={{ width: "15vw", height: "30vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <img style={{ width: "100%", height: "100%", objectFit: "contain" }} src={`${process.env.REACT_APP_LOCAL_URL}/uploads/inward/${selectedinwardEntry.photo}`} alt="inward Upload" />
                                                </div>
                                            </div>
                                            <div className="modalfooter">
                                                <button className="btn btn-secondary" onClick={closeModal}>Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* Outward History */}
                            <div className="tab-pane fade" id="outward" role="tabpanel" aria-labelledby="outward-tab">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Vendor Name</th>
                                                    <th>Consignment Date</th>
                                                    <th>Courier Company</th>
                                                    <th>Dispatched By </th>
                                                    <th>Consignment Subject</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItemsOutward.map((entry) => (
                                                    <tr key={entry.id}>
                                                        <td>{entry.entityName}</td>
                                                        <td> {formatDate(entry.consignmentdate)}</td>
                                                        <td>{entry.courierCompany}</td>
                                                        <td>{entry.ename}</td>
                                                        <td>{entry.consignmentType}</td>
                                                        <td><button className="btn btn-outline-info" onClick={() => handleOutwardHistory(entry)}>View</button></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
                                            </li>
                                            {Array.from({ length: Math.ceil(outwardHistory.length / itemsPerPage) || 1 }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                                    <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(outwardHistory.length / itemsPerPage) ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {selectedOutwardEntry && (
                                <div
                                    isOpen={outwardModalOpen}
                                    onRequestClose={closeModal} className="modal-body custom-modal"
                                    style={{ position: "absolute" }}
                                >
                                    <div className="container-fluid incustom-modal" style={{ overflowY: "auto" }} >
                                        <div className="main-container" style={{ height: "100%" }}>
                                            <div className="modalnav">
                                                <h2>Outward Details</h2>
                                            </div>
                                            <div className="modalmain">
                                                <div>
                                                    <p><strong>Client Name:</strong> {selectedOutwardEntry.entityName}</p>
                                                    <p><strong>Client Code:</strong> {selectedOutwardEntry.code}</p>
                                                    <p><strong>Address:</strong> {selectedOutwardEntry.address}</p>
                                                    <p><strong>Sate:</strong> {selectedOutwardEntry.state}</p>
                                                    <p><strong>City:</strong> {selectedOutwardEntry.city}</p>
                                                    <p><strong>Pincode:</strong> {selectedOutwardEntry.pincode}</p>
                                                    <p><strong>Courier Company:</strong> {selectedOutwardEntry.courierCompany}</p>
                                                    <p><strong>Consignment No:</strong> {selectedOutwardEntry.consignmentNo}</p>
                                                    <p><strong>Consignment Date:</strong>  {formatDate(selectedOutwardEntry.consignmentdate)}</p>
                                                    <p><strong>Ship From:</strong> {selectedOutwardEntry.shipFrom}</p>
                                                    <p><strong>Ship To:</strong> {selectedOutwardEntry.shiptoaddress}</p>
                                                    <p><strong>Dispatched By:</strong> {selectedOutwardEntry.ename}</p>
                                                    <p><strong>Consignment Type:</strong> {selectedOutwardEntry.consignmentType}</p>
                                                    <p><strong>Remark:</strong> {selectedOutwardEntry.remark}</p>
                                                    <p><strong>Invoice:</strong> {selectedOutwardEntry.invoice || "-"}</p>
                                                </div>
                                                <div style={{ width: "15vw", height: "30vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <img style={{ width: "100%", height: "100%", objectFit: "contain" }} src={`${process.env.REACT_APP_LOCAL_URL}/uploads/outward/${selectedOutwardEntry.photo}`} alt="Outward Upload" />
                                                </div>
                                            </div>
                                            <div className="modalfooter">
                                                <button className="btn btn-secondary" onClick={closeModal}>Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div >
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {
                isEditModalOpen && <EditVendor
                    vendor={vendor}
                    onClose={handleCloseEditModal}
                />
            }
            {/* Pass onUpdate function */}
            {/* Sales History */}
            {purchaseHistoryModal && <PurchaseHistory
                purchaseDetails={purchaseHistoryDetails} onClose={handleClosePurchaseHistory} />}
            {/* Inward */}
            {inwardHistoryModal && <InwardHistory
                inwardDetails={inwardHistoryDetails} onClose={handleCloseInwardHistory} />}
            {/* Outward */}
            {outwardHistoryModal && <OutwardHistory
                outwardDetails={outwardHistoryDetails} onClose={handleCloseOutwardHistory} />}

        </div>
    );
};

export default VendorDesc;



































