import React, { useState, useEffect } from "react";
import axios from "axios";
import EditPurchase from "./EditPurchase";
import { ToastContainer, toast } from 'react-toastify';
import myImage from '../../images/brand_images/default_employee.jpg';
import 'react-toastify/dist/ReactToastify.css';

const PurchaseDesc = ({ purchase, onClose }) => {
    const [purchaseHistory, setPurchaseHistory] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {

        fetchPurchaseHistory();
    }, [purchase]);
    const fetchPurchaseHistory = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_LOCAL_URL}/api/purchase/history/${purchase.id}`
            );
            setPurchaseHistory(response.data);
        } catch (error) {
            console.error("Error fetching purchase history:", error);
        }
    };
    const handleEditPurchase = () => {
        setIsEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
    };
    const handleUpdatePurchaseList = () => {
        fetchPurchaseHistory();
        toast.success("Data saved Successfully")
    };

    // Function to format the date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} `;
    };
    
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = purchaseHistory.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div>
            <div className="card-body p-4 bg-white rounded shadow-sm">
                <div className="row">

                    <div className="col-md-9 d-flex  justify-content-between px-3">
                        <div>
                            <h2 style={{ color: "#00509d" }} className="title-detail fw-bolder font-bold m-0">
                                {purchase.vendorName}
                            </h2>
                            <h6 style={{ color: "#00509d" }} className="title-detail fw-bolder font-bold m-0">
                                Vendor Code:  {purchase.vendorCode}
                            </h6>
                            <small>Created By - {purchase.username || "N/A"}</small>
                        </div>
                        <div>
                            <p className="m-0">
                                <span> Invoice Number: {purchase.invoiceNumber || "N/A"}</span>
                            </p>
                            <p className="m-0">
                                <span> Purchase Date:  {formatDate(purchase.date)  || "N/A"}</span>
                            </p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="p-2 barcode-inner d-flex flex-column gap-1">
                            <div className="d-flex gap-2 align-items-center justify-content-center">
                                <button style={{ whiteSpace: "nowrap" }} onClick={onClose} className="button_edit">
                                    <i className="fa fa-arrow-left"></i>   Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md-12">
                        
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a
                                    style={{ color: "grey" }}
                                    className="nav-link button-link active show"
                                    id="details-tab"
                                    data-toggle="tab"
                                    href="#details"
                                    role="tab"
                                    aria-controls="details"
                                    aria-selected="true"
                                >
                                    Purchase Details
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div
                                className="tab-pane fade active show"
                                id="details"
                                role="tabpanel"
                                aria-labelledby="details-tab"
                            >
                                <div className="row">
                                    <div className="col-md-9 d-flex" style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto", overflowX: "hidden" }}>
                                        <table className="table table-bordered table-hover" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td >
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Vendor Name:</span> {purchase.vendorName || "-"}</p>
                                                    </td>

                                                    <td >
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Vendor Code:</span> {purchase.vendorCode || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Invoice Number:</span> {purchase.invoiceNumber || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Payment Mode:</span>  {purchase.paymentMode || "-"}</p>
                                                    </td>

                                                </tr>

                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Purchase Amount:</span> {purchase.purchaseAmount || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Purchased Approved By: </span> {purchase.purchasedApprovedBy || "-"}</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Purchase Date:</span>  {formatDate(purchase.purchaseDate) || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Purchase Items: </span>{purchase.purchaseItems && JSON.parse(purchase.purchaseItems).map(product => product.name).join(', ')}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Address: </span> {purchase.vendorAddress || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Purchase Description: </span>{purchase.purchaseDescription || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Purchased By:</span> {purchase.purchasedBy || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Remark: </span>{purchase.remark || "-"}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* Image Section */}
                                    <div class="col-md-3 pt-2 text-center">
                                        <img
                                            src={purchase.upload1
                                                ? `${process.env.REACT_APP_LOCAL_URL}/uploads/purchase_photo/${purchase.upload1}`
                                                : myImage}
                                            style={{ width: "90px" }}
                                            className="employee-image"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Purchase History */}
                            <div className="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Event</th>
                                                    <th>Date</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItems.map((entry) => (
                                                    <tr key={entry.event_id}>
                                                        <td>{entry.event}</td>
                                                        <td> {formatDate(entry.date)}</td>
                                                        <td>{entry.description}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {/* Pagination */}
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                            </li>
                                            {Array.from({ length: Math.ceil(purchaseHistory.length / itemsPerPage) || 1 }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(purchaseHistory.length / itemsPerPage) && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isEditModalOpen && <EditPurchase
                purchase={purchase}
                onUpdate={handleUpdatePurchaseList}
                onClose={handleCloseEditModal}
            />}
        </div>
    );
};

export default PurchaseDesc;





































