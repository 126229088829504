import React, { useState, useEffect } from 'react';

const OutwardHistory = ({ outwardDetails, onClose }) => {
    console.log(outwardDetails);
    const handleClose = () => {
        onClose();
    };
    // Function to format the date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} `;
    };

    return (
        <div id="add" className="modal fade show" role="dialog" style={{ display: "block" }}>
            <div style={{ borderRadius: "20px" }} className="modal-dialog modal-lg overflow-hidden">
                <div className="modal-content">
                    <div style={{ backgroundColor: "#00509D", color: "white" }} className="modal-header">
                        <h5 className="modal-title">Outward History</h5>
                        <button type="button" className="button_details " onClick={handleClose}>
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                    <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                        <div className="modalmain">
                            <div>
                                <p><strong>Client Name:</strong> {outwardDetails.entityName}</p>
                                <p><strong>Client Code:</strong> {outwardDetails.code}</p>
                                <p><strong>Address:</strong> {outwardDetails.address}</p>
                                <p><strong>Sate:</strong> {outwardDetails.state}</p>
                                <p><strong>City:</strong> {outwardDetails.city}</p>
                                <p><strong>Pincode:</strong> {outwardDetails.pincode}</p>
                                <p><strong>Courier Company:</strong> {outwardDetails.courierCompany}</p>
                                <p><strong>Consignment No:</strong> {outwardDetails.consignmentNo}</p>
                                <p><strong>Consignment Date:</strong>  {formatDate(outwardDetails.consignmentdate)}</p>
                                <p><strong>Ship From:</strong> {outwardDetails.shipFrom}</p>
                                <p><strong>Ship To:</strong> {outwardDetails.shiptoaddress}</p>
                                <p><strong>Dispatched By:</strong> {outwardDetails.ename}</p>
                                <p><strong>Consignment Type:</strong> {outwardDetails.consignmentType}</p>
                                <p><strong>Remark:</strong> {outwardDetails.remark}</p>
                                <p><strong>Invoice:</strong> {outwardDetails.invoice || "-"}</p>
                            </div>
                            <div style={{ width: "15vw", height: "30vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <img style={{ width: "100%", height: "100%", objectFit: "contain" }} src={`${process.env.REACT_APP_LOCAL_URL}/uploads/outward/${outwardDetails.photo}`} alt="outward Upload" />
                            </div>
                        </div>
                    </div>
                    <div style={{ backgroundColor: "#00509D", color: "white" }} className="modal-footer">
                        <button type="button" className="button_details" onClick={handleClose}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OutwardHistory;
