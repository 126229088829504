import React, { useState, useEffect } from "react";
import axios from "axios";
// import Modal from "react-modal";
import EditService from "./EditService";
import "./service.css"

const ServiceDesc = ({ service, onClose }) => {
    const [serviceHistory, setServiceHistory] = useState([]);
    const [vendorHistory, setVendorHistory] = useState([]);
    const [purchaseHistory, setpurchaseHistory] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [selectedEntry, setSelectedEntry] = useState(null);
    const [selectedVendorEntry, setSelectedVendorEntry] = useState(null);
    const [selectedPurchaseEntry, setSelectedPurchaseEntry] = useState(null);
    const [salesModalOpen, setSalesModalOpen] = useState(false);
    const [vendorModalOpen, setVendorModalOpen] = useState(false);
    const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);

    useEffect(() => {
        const fetchServiceHistory = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_LOCAL_URL}/api/service/provide/${service.id}`
                );
                setServiceHistory(response.data);
            } catch (error) {
                console.error("Error fetching service history:", error);
            }
        };

        fetchServiceHistory();
    }, [service]);

    useEffect(() => {
        const fetchPurchaseHistory = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_LOCAL_URL}/api/purchase/provide/${service.id}`
                );
                setpurchaseHistory(response.data);
            } catch (error) {
                console.error("Error fetching purchase history:", error);
            }
        };

        console.log("PU", purchaseHistory)
        fetchPurchaseHistory();
    }, [service.cellPadding]);

    useEffect(() => {
        const fetchVendorHistory = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_LOCAL_URL}/api/vendor/provide/${service.id}`
                );
                setVendorHistory(response.data);
            } catch (error) {
                console.error("Error fetching vendor history:", error);
            }
        };
        fetchVendorHistory();
    }, [service.id]);



    const handleEditService = () => {
        setIsEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
    };

    const handleViewClick = (entry) => {
        if (entry.vendorCode) {
            setSelectedVendorEntry(entry);
            setVendorModalOpen(true);
        } else {
            setSelectedEntry(entry);
            setSalesModalOpen(true);
        }
    };

    const handleViewPurchaseClick = (entry) => {
        setSelectedPurchaseEntry(entry);
        setPurchaseModalOpen(true);
    };

    const closeModal = () => {
        setSalesModalOpen(false);
        setVendorModalOpen(false);
        setPurchaseModalOpen(false);
        setSelectedVendorEntry(null);
        setSelectedEntry(null);
        setSelectedPurchaseEntry(null);
    };
    // Function to format the date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} `;
    };
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItemsSales = serviceHistory.slice(indexOfFirstItem, indexOfLastItem);
    const currentItemsVendor = vendorHistory.slice(indexOfFirstItem, indexOfLastItem);
    const currentItemsPurchase = purchaseHistory.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div>
            <div className="card-body p-4">
                <div className="row">
                    <div className="col-md-9 d-flex flex-column gap-2">
                        <h4 className="title-detail font-bold" style={{ color: "black" }}>
                            Service Name - {service.name}
                        </h4>
                        <h5 className="assetdetail">
                            <span className="assettype" style={{ color: "black" }}> Service Code: {service.code}</span>
                        </h5>
                        <small>Created By - {service.username || "-"}</small>
                    </div>
                    <div className="col-md-3">
                        <div className="p-2 barcode-inner">
                            <div className="assetbarcode d-flex flex-column gap-3">
                                <button onClick={onClose} className="btn btn-outline-primary">
                                <i className="fa fa-arrow-left"></i> Back
                                </button>
                                {/* <button onClick={handleEditService} className="btn btn-outline-primary">
                                    Edit Service
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a
                                    className="nav-link active show"
                                    id="details-tab"
                                    data-toggle="tab"
                                    href="#details"
                                    role="tab"
                                    aria-controls="details"
                                    aria-selected="true"
                                >
                                    Service Details
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="history-tab"
                                    data-toggle="tab"
                                    href="#history"
                                    role="tab"
                                    aria-controls="history"
                                    aria-selected="false"
                                >
                                    Sales History
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="vendorhistory-tab"
                                    data-toggle="tab"
                                    href="#vendorhistory"
                                    role="tab"
                                    aria-controls="vendorhistory"
                                    aria-selected="false"
                                >
                                    Vendor History
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="purchasehistory-tab"
                                    data-toggle="tab"
                                    href="#purchasehistory"
                                    role="tab"
                                    aria-controls="purchasehistory"
                                    aria-selected="false"
                                >
                                    Purchase History
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div
                                className="tab-pane fade active show"
                                id="details"
                                role="tabpanel"
                                aria-labelledby="details-tab"
                            >
                                <div className="row">
                                    <div className="col-md-12" style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto", overflowX: "hidden" }}>
                                        <table className="table table-hover" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Service Name:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assettype2">{service.name || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Service Code:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetstatus">{service.code || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Service Remark:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{service.remark || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Created At:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial"> {formatDate(service.created_at) || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Total Services :- </p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{serviceHistory.length}</p>
                                                    </td>
                                                </tr>
                                                {/* Add other service details here */}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/* sales History */}
                            <div className="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Client Name</th>
                                                    <th>Client Code</th>
                                                    <th>Company Name</th>
                                                    <th>Invoice Number</th>
                                                    <th>Sales Date</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItemsSales.map((entry) => (
                                                    <tr key={entry.event_id}>
                                                        <td>{entry.clientName}</td>
                                                        <td>{entry.clientCode}</td>
                                                        <td>{entry.companyName}</td>
                                                        <td>{entry.invoiceNumber || "-"}</td>
                                                        <td> {formatDate(entry.salesDate)}</td>
                                                        <td>
                                                            <button className="btn btn-outline-info" onClick={() => handleViewClick(entry)}>View</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
                                            </li>
                                            {Array.from({ length: Math.ceil(serviceHistory.length / itemsPerPage) || 1 }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                                    <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(serviceHistory.length / itemsPerPage) ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {selectedEntry && (
                                <div isOpen={salesModalOpen} onRequestClose={closeModal} className="modal-body custom-modal" style={{position:"absolute"}}>
                                    <div className="container-fluid incustom-modal"  style={{overflowY:"auto"}}>
                                        <div className="main-container">
                                            <div className="modalnav">
                                                <h2>Sales Details</h2>
                                            </div>
                                            <div className="modalmain" style={{overflowY:"auto"}}>
                                                <div className="modalmainleft">
                                                    <p><strong>Vendor Code:</strong> {selectedEntry.vendorCode}</p>
                                                    <p><strong>Invoice Number:</strong> {selectedEntry.invoiceNumber}</p>
                                                    <p><strong>Remark:</strong> {selectedEntry.remark}</p>
                                                    <p><strong>Selected Service Products:</strong>
                                                        {selectedEntry.selectedServiceProducts
                                                            ? JSON.parse(selectedEntry.selectedServiceProducts).map(product => product.name).join(', ')
                                                            : 'No selected products'}
                                                    </p>
                                                    <p><strong>Total Sales Amount:</strong> {selectedEntry.totalSalesAmount}</p>
                                                    <p><strong>Address:</strong> {selectedEntry.address}</p>
                                                    <p><strong>Client Code:</strong> {selectedEntry.clientCode}</p>
                                                    <p><strong>Client Name:</strong> {selectedEntry.clientName}</p>
                                                    <p><strong>Client ID:</strong> {selectedEntry.client_id}</p>
                                                    <p><strong>Company Name:</strong> {selectedEntry.companyName}</p>
                                                    <p><strong>Company Name ID:</strong> {selectedEntry.companyName_id}</p>
                                                    <p><strong>Email ID:</strong> {selectedEntry.emailId1}</p>
                                                    <p><strong>Employee ID:</strong> {selectedEntry.employee_id}</p>
                                                    <p><strong>ID:</strong> {selectedEntry.id}</p>
                                                    <p><strong>Sales Date:</strong> {formatDate(selectedEntry.salesDate).toLocaleDateString()}</p>
                                                    <p><strong>Sales Description:</strong> {selectedEntry.salesDescription}</p>
                                                    <p><strong>Sales Executive Name:</strong> {selectedEntry.salesExecutiveName}</p>
                                                    <p><strong>Mobile Number:</strong> {selectedEntry.mobileNo1}</p>
                                                    <p><strong>Upload Picture:</strong> <img src="{selectedEntry.uploadPicture}" alt="Uploaded Image" /></p>

                                                </div>
                                                <div className="modalmainright" style={{ width: "15vw", height: "30vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <img
                                                        style={{ width: "100%", height: "100%", objectFit: "contain" }}
                                                        src={`${process.env.REACT_APP_LOCAL_URL}/uploads/sales_photo/${selectedEntry.uploadPicture}`}
                                                        alt="Vendor Upload"
                                                    />
                                                </div>
                                            </div>
                                            <div className="modalfooter">
                                                <button className="btn btn-secondary" onClick={closeModal}>Close</button>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            )}
                            {/* vendor history  */}
                            <div className="tab-pane fade" id="vendorhistory" role="tabpanel" aria-labelledby="vendorhistory-tab">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Vendor Name</th>
                                                    <th>Vendor Code</th>
                                                    <th>Person Name</th>
                                                    <th>Mobile No.</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItemsVendor.map((entry) => (
                                                    <tr key={entry.event_id}>
                                                        <td>{entry.vendorName}</td>
                                                        <td>{entry.vendorCode}</td>
                                                        <td>{entry.contactPersonName1}</td>
                                                        <td>{entry.mobileNo1}</td>
                                                        <td>
                                                            <button className="btn btn-outline-info" onClick={() => handleViewClick(entry)}>View</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
                                            </li>
                                            {Array.from({ length: Math.ceil(vendorHistory.length / itemsPerPage) || 1 }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                                    <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(vendorHistory.length / itemsPerPage) ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {selectedVendorEntry && (
                                <div
                                    isOpen={vendorModalOpen}
                                    onRequestClose={closeModal}
                                    className="modal-body custom-modal" style={{position:"absolute"}}
                                >
                                    <div className="container-fluid incustom-modal"  style={{overflowY:"auto"}}>
                                    <div className="main-container">
                                        <div className="modalnav">
                                            <h2>Vendor Details</h2>
                                        </div>
                                        <div className="modalmain">
                                            <div className="modalmainleft">
                                                <p><strong>Vendor Code:</strong> {selectedVendorEntry.vendorCode}</p>
                                                <p><strong>Vendor Name:</strong> {selectedVendorEntry.vendorName}</p>
                                                <p><strong>Address:</strong> {selectedVendorEntry.address}</p>
                                                <p><strong>City:</strong> {selectedVendorEntry.city}</p>
                                                <p><strong>Contact Person 1:</strong> {selectedVendorEntry.contactPersonName1}</p>
                                                <p><strong>Contact Person 2:</strong> {selectedVendorEntry.contactPersonName2}</p>
                                                <p><strong>Email Id 1:</strong> {selectedVendorEntry.emailId1}</p>
                                                <p><strong>Email Id 2:</strong> {selectedVendorEntry.emailId2}</p>
                                                <p><strong>GST No:</strong> {selectedVendorEntry.gstNo}</p>
                                                <p><strong>ID:</strong> {selectedVendorEntry.id}</p>
                                                <p><strong>Mobile No 1:</strong> {selectedVendorEntry.mobileNo1}</p>
                                                <p><strong>Mobile No 2:</strong> {selectedVendorEntry.mobileNo2}</p>
                                                <p><strong>Mobile No 3:</strong> {selectedVendorEntry.mobileNo3}</p>
                                                <p><strong>PAN No:</strong> {selectedVendorEntry.panNo}</p>
                                                <p><strong>Pincode:</strong> {selectedVendorEntry.pincode}</p>
                                                <p><strong>Remark:</strong> {selectedVendorEntry.remark}</p>
                                                <p><strong>Selected Service Products:</strong>
                                                    {selectedVendorEntry.selectedServices
                                                        ? JSON.parse(selectedVendorEntry.selectedServices).map(product => product.name).join(', ')
                                                        : 'No selected products'}
                                                </p>
                                                <p><strong>State:</strong> {selectedVendorEntry.state}</p>
                                                <p><strong>Created At:</strong> {formatDate(selectedVendorEntry.createdAt).toLocaleDateString()}</p>
                                                <p><strong>Updated At:</strong> {formatDate(selectedVendorEntry.updatedAt).toLocaleDateString()}</p>
                                                <p><strong>Upload 1:</strong> {selectedVendorEntry.upload1 ? <img src={selectedVendorEntry.upload1} alt="Upload 1" /> : 'No upload'}</p>
                                                <p><strong>Upload 2:</strong> {selectedVendorEntry.upload2 ? <img src={selectedVendorEntry.upload2} alt="Upload 2" /> : 'No upload'}</p>
                                                <p><strong>Upload 3:</strong> {selectedVendorEntry.upload3 ? <img src={selectedVendorEntry.upload3} alt="Upload 3" /> : 'No upload'}</p>
                                                <p><strong>Upload 4:</strong> {selectedVendorEntry.upload4 ? <img src={selectedVendorEntry.upload4} alt="Upload 4" /> : 'No upload'}</p>
                                                <p><strong>Upload 5:</strong> {selectedVendorEntry.upload5 ? <img src={selectedVendorEntry.upload5} alt="Upload 5" /> : 'No upload'}</p>

                                            </div>
                                            <div className="modalmainright" style={{ width: "15vw", height: "30vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <img
                                                    style={{ width: "100%", height: "100%", objectFit: "contain" }}
                                                    src={`${process.env.REACT_APP_LOCAL_URL}/uploads/sales_photo/${selectedVendorEntry.upload1}`}
                                                    alt="Vendor Upload"
                                                />
                                            </div>
                                        </div>
                                        <div className="modalfooter">
                                            <button className="btn btn-secondary" onClick={closeModal}>Close</button>
                                        </div>

                                    </div>

                                    </div>
                                   

                                </div>
                            )}
                            {/* purchase History  */}
                            <div className="tab-pane fade" id="purchasehistory" role="tabpanel" aria-labelledby="purchasehistory-tab">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Vendor Name</th>
                                                    <th>Vendor Code</th>
                                                    <th>Address</th>
                                                    <th>Invoice Number</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItemsPurchase.map((entry) => (
                                                    <tr key={entry.event_id}>
                                                        <td>{entry.vendorName}</td>
                                                        <td>{entry.vendorCode}</td>
                                                        <td>{entry.vendorAddress || "-"}</td>
                                                        <td>{entry.invoiceNumber || "-"}</td>
                                                        <td>
                                                            <button className="btn btn-outline-info" onClick={() => handleViewPurchaseClick(entry)}>View</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
                                            </li>
                                            {Array.from({ length: Math.ceil(purchaseHistory.length / itemsPerPage) || 1 }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                                    <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(purchaseHistory.length / itemsPerPage) ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {selectedPurchaseEntry && (
                                <div
                                    isOpen={purchaseModalOpen}
                                    onRequestClose={closeModal}
                                    className="modal-body custom-modal" style={{position:"absolute"}}
                                >
                                    <div className="container-fluid incustom-modal"  style={{overflowY:"auto"}}>
                                    <div className="main-container">
                                        <div className="modalnav">
                                            <h2>Purchase Details</h2>
                                        </div>
                                        <div className="modalmain">
                                            <div className="modalmainleft">
                                                <p><strong>Vendor Code:</strong> {selectedPurchaseEntry.vendorCode}</p>
                                                <p><strong>Vendor Name:</strong> {selectedPurchaseEntry.vendorName}</p>
                                                <p><strong>Vendor Address:</strong> {selectedPurchaseEntry.vendorAddress}</p>
                                                <p><strong>Invoice Number:</strong> {selectedPurchaseEntry.invoiceNumber}</p>
                                                <p><strong>Payment Mode:</strong> {selectedPurchaseEntry.paymentMode}</p>
                                                <p><strong>Purchase Amount:</strong> {selectedPurchaseEntry.purchaseAmount}</p>
                                                <p><strong>Purchase Date:</strong> {formatDate(selectedPurchaseEntry.purchaseDate).toLocaleDateString()}</p>
                                                <p><strong>Purchase Description:</strong> {selectedPurchaseEntry.purchaseDescription}</p>
                                                <p><strong>Purchase Items:</strong>
                                                    {selectedPurchaseEntry.purchaseItems
                                                        ? JSON.parse(selectedPurchaseEntry.purchaseItems).map(product => product.name).join(', ')
                                                        : 'No purchased items'}
                                                </p>
                                                <p><strong>Purchased Approved By:</strong> {selectedPurchaseEntry.purchasedApprovedBy}</p>
                                                <p><strong>Purchased Approved By ID:</strong> {selectedPurchaseEntry.purchasedApprovedById}</p>
                                                <p><strong>Purchased By:</strong> {selectedPurchaseEntry.purchasedBy}</p>
                                                <p><strong>Purchased By ID:</strong> {selectedPurchaseEntry.purchasedById}</p>
                                                <p><strong>Remark:</strong> {selectedPurchaseEntry.remark}</p>
                                                <p><strong>Created At:</strong> {formatDate(selectedPurchaseEntry.createdAt).toLocaleDateString()}</p>
                                                <p><strong>Updated At:</strong> {formatDate(selectedPurchaseEntry.updatedAt).toLocaleDateString()}</p>
                                                <p><strong>Upload 1:</strong> {selectedPurchaseEntry.upload1 ? <img src={selectedPurchaseEntry.upload1} alt="Upload 1" /> : 'No upload'}</p>
                                                <p><strong>Upload 2:</strong> {selectedPurchaseEntry.upload2 ? <img src={selectedPurchaseEntry.upload2} alt="Upload 2" /> : 'No upload'}</p>
                                                <p><strong>Total Sales Amount:</strong> {selectedPurchaseEntry.totalSalesAmount}</p>

                                            </div>
                                            <div className="modalmainright" style={{ width: "15vw", height: "30vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <img
                                                    style={{ width: "100%", height: "100%", objectFit: "contain" }}
                                                    src={`${process.env.REACT_APP_LOCAL_URL}/uploads/sales_photo/${selectedPurchaseEntry.upload1}`}
                                                    alt="Vendor Upload"
                                                />
                                            </div>
                                        </div>
                                        <div className="modalfooter">
                                            <button className="btn btn-secondary" onClick={closeModal}>Close</button>
                                        </div>

                                    </div>
                                    </div>
                                    
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {isEditModalOpen && <EditService service={service} onClose={handleCloseEditModal} />}
        </div>
    );
};

export default ServiceDesc;