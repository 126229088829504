import React, { useState, useEffect } from "react";
import axios from "axios";
import EditOffice from "./EditOffice";
import myImage from '../../images/brand_images/default_employee.jpg';

const OfficeDesc = ({ office, onClose }) => {
    const [officeHistory, setOfficeHistory] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {
        const fetchOfficeHistory = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_LOCAL_URL}/api/office/history/${office.id}`
                );
                setOfficeHistory(response.data);
            } catch (error) {
                console.error("Error fetching office history:", error);
            }
        };

        fetchOfficeHistory();
    }, [office]);

    const handleEditOffice = () => {
        setIsEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
    };

    // Function to format the date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} `;
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = officeHistory.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (

        <div>
            <div className="card-body p-4 bg-white rounded shadow-sm">
                <div className="row">

                    <div className="col-md-9 d-flex  justify-content-between px-3">
                        <div>
                            <h2 style={{ color: "#00509d" }} className="title-detail fw-bolder font-bold m-0">
                                {office.officeName}
                            </h2>
                            <small>Created By - {office.username || "N/A"}</small>
                        </div>
                        <div>
                            <p className="m-0">
                                <i class="fa fa-envelope" aria-hidden="true"></i> <span> Email: {office.email1 || "N/A"}</span>
                            </p>
                            <p className="m-0">
                                <i class="fa fa-phone"></i> <span> Phone: {office.mobile1 || "N/A"}</span>
                            </p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="p-2 barcode-inner d-flex flex-column gap-1">
                            <div className="d-flex gap-2 align-items-center justify-content-center">
                                <button style={{ whiteSpace: "nowrap" }} onClick={onClose} className="button_edit">
                                    <i className="fa fa-arrow-left"></i>   Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a
                                    style={{ color: "grey" }}
                                    className="nav-link button-link active show"
                                    id="details-tab"
                                    data-toggle="tab"
                                    href="#details"
                                    role="tab"
                                    aria-controls="details"
                                    aria-selected="true"
                                >
                                    Office Details
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div
                                className="tab-pane fade active show"
                                id="details"
                                role="tabpanel"
                                aria-labelledby="details-tab"
                            >
                                <div className="row">
                                    <div className="col-md-9 d-flex" style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto", overflowX: "hidden" }}>
                                        <table className="table table-bordered table-hover" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td >
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Office Name:</span> {office.officeName || "-"}</p>
                                                    </td>

                                                    <td >
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Person Name:</span> {office.ename || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Mobile 1 :</span> {office.mobile1 || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Mobile 2 :</span>  {office.mobile2 || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Mobile 3:</span> {office.mobile3 || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Email ID 1 :</span> {office.email1 || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Email ID 2 :</span> {office.email2 || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Address :</span> {office.address || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >City :</span> {office.city || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >State :</span> {office.state || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Pincode :</span> {office.pincode || "-"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 text-dark"><span className="fw-bolder text-black" >Remark :</span> {office.remarks || "-"}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* Image Section */}
                                    <div class="col-md-3 pt-2 text-center">
                                        <img
                                            src={office.picture
                                                ? `${process.env.REACT_APP_LOCAL_URL}/uploads/office/${office.picture}`
                                                : myImage}
                                            style={{ width: "90px" }}
                                            className="employee-image"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Office History */}
                            <div className="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Event</th>
                                                    <th>Date</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItems.map((entry) => (
                                                    <tr key={entry.event_id}>
                                                        <td>{entry.event}</td>
                                                        <td> {formatDate(entry.date)}</td>
                                                        <td>{entry.description}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {/* Pagination */}
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                            </li>
                                            {Array.from({ length: Math.ceil(officeHistory.length / itemsPerPage) || 1 }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(officeHistory.length / itemsPerPage) && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isEditModalOpen && <EditOffice
                office={office}
                onClose={handleCloseEditModal}
            />}
        </div>
    );
};

export default OfficeDesc;




























































