// new 
import React, { useState, useEffect } from "react";
import axios from "axios";
import AddSales from "./AddSales";
import SalesDesc from "./SalesDesc";
import EditSales from './EditSales';
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import SearchBar from "../../components/sidebar/SearchBar";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';

function SalesList({ handleLogout, username }) {
    const [sales, setSales] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filteredSales, setFilteredSales] = useState([]);
    const [selectedSales, setSelectedSales] = useState(null);
    const [showSalesDetails, setShowSalesDetails] = useState(false);
    const [isAddSalesModalOpen, setIsAddSalesModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [deleteSales, setDeleteSales] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        fetchSales();
    }, []);

    useEffect(() => {
        filterSalesByDate();
    }, [sales, startDate, endDate]);

    const fetchSales = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/sales`);
            const sortedSales = response.data.sort((a, b) => b.id - a.id);
            setSales(sortedSales);
        } catch (error) {
            console.error("Error fetching sales:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const filterSalesByDate = () => {
        if (startDate && endDate) {
            const filtered = sales.filter(sale => {
                const saleDate = new Date(sale.salesDate).setHours(0, 0, 0, 0);
                const start = new Date(startDate).setHours(0, 0, 0, 0);
                const end = new Date(endDate).setHours(0, 0, 0, 0);
                return saleDate >= start && saleDate <= end;
            });
            setFilteredSales(filtered);
        } else {
            setFilteredSales(sales);
        }
    };

    const handleAddSales = () => {
        setIsAddSalesModalOpen(true);
    };

    const handleCloseSalesModal = () => {
        setIsAddSalesModalOpen(false);
        setIsEditModalOpen(false);
    };

    const handleSalesDetails = (sales) => {
        setSelectedSales(sales);
        setShowSalesDetails(true);
    };

    const handleEditSalesClick = (sales) => {
        setSelectedSales(sales);
        setIsEditModalOpen(true);
    };

    const handleEditSalesClose = () => {
        setSelectedSales(null);
    };

    const handleBackToTable = () => {
        setSelectedSales(null);
        setShowSalesDetails(false);
    };

    const handleDeleteSales = (sales) => {
        setDeleteSales(sales);
        setIsDeleteModalOpen(true);
    };

    const handleDeleteConfirmation = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/sales/${deleteSales.id}`);

            const deletedSales = { ...deleteSales, reason: deleteReason };
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedSales);

            setSales(prevSales => prevSales.filter(sale => sale.id !== deleteSales.id));
            setIsDeleteModalOpen(false);

            toast.success("Sales deleted successfully");
        } catch (error) {
            console.error("Error deleting sales:", error);
            toast.error("Error deleting sales");
        }
    };

    // Function to format the date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} `;
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredSales.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div className='d-flex w-100 h-100 bg-white'>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    {showSalesDetails && selectedSales ? (
                        <SalesDesc
                            sales={selectedSales}
                            onClose={handleBackToTable}
                        />) : (
                        <div className="row">
                            <div className="col-xl-12">
                                <div style={{ borderRadius: "20px", border: "1px solid #003459" }} className='overflow-hidden'>
                                    <div style={{ background: "rgb(33,131,128)", background: "#00509d " }} className="row no-gutters align-items-center p-3">
                                        <div className="col">
                                            <div className="text-xs font-weight-bold text-white text-uppercase d-flex align-items-center justify-content-between" style={{ fontSize: '1.5rem' }}>
                                                <div className="nunito text-white">Sales List</div>
                                                <div className=" d-flex gap-3">
                                                    <div className="d-flex align-items-center justify-content-center gap-2">
                                                        <label className='nunito text-white p-0 m-0'>Filter:-</label>
                                                        <input
                                                            type="date"
                                                            value={startDate}
                                                            onChange={(e) => setStartDate(e.target.value)}
                                                            className="form-control button_details datepicker"
                                                        />
                                                        <input
                                                            type="date"
                                                            value={endDate}
                                                            onChange={(e) => setEndDate(e.target.value)}
                                                            className="form-control button_details datepicker"
                                                        />
                                                    </div>
                                                </div>
                                                <button onClick={handleAddSales} className="button_details"><i className="fa fa-plus"></i> Add New Sale</button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='m-0 p-0' />
                                    <div className=''>
                                        <div className="card-body">
                                            <div className="" style={{ maxHeight: "610px", overflowY: "auto" }}>
                                                {isLoading ? (
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <ThreeDots color="#00509d" height={80} width={80} />
                                                    </div>
                                                ) : (
                                                    <table className="table table-bordered" style={{ width: "100%" }}>
                                                        <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                            <tr>
                                                                <th>Client Name</th>
                                                                <th>Client Code</th>
                                                                <th>Selling Company </th>
                                                                <th>Sales Date</th>
                                                                <th>Sales Amount</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <style>
                                                                {`.hyperlink:hover {color: blue;}`}
                                                            </style>
                                                            {currentItems.length === 0 ? (
                                                                <tr>
                                                                    <td colSpan="12" className="text-center">No Inquiry Entries.</td>
                                                                </tr>
                                                            ) : (
                                                                currentItems.map((sale, index) => (
                                                                    <tr key={index}>
                                                                        <td className='hyperlink' style={{ cursor: "pointer" }} onClick={() => handleSalesDetails(sale)}>{sale.clientName}</td>
                                                                        <td>{sale.clientCode}</td>
                                                                        <td>{sale.companyName}</td>
                                                                        <td>{formatDate(sale.salesDate)}</td>
                                                                        <td>{sale.totalSalesAmount}</td>
                                                                        <td>
                                                                            <div className="d-flex align-items-center justify-content-start gap-3 z-99">
                                                                                <div className="btn-group">
                                                                                    <button
                                                                                        className="button_action"
                                                                                        type="button"
                                                                                        data-toggle="dropdown"
                                                                                        aria-haspopup="true"
                                                                                        aria-expanded="false"
                                                                                    >
                                                                                        <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                                                                    </button>
                                                                                    <div className="dropdown-menu actionmenu">

                                                                                        <a className="dropdown-item" href="#" onClick={() => handleEditSalesClick(sale)}>
                                                                                            <i className="fas fa-edit"></i> Edit
                                                                                        </a>
                                                                                        <a className="dropdown-item" href="#" onClick={() => handleDeleteSales(sale)}>
                                                                                            <i className="fa fa-trash"></i> Delete
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            )}
                                                        </tbody>
                                                    </table>
                                                )}
                                            </div>
                                            <ul className="pagination mt-2">
                                                <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                    <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
                                                </li>
                                                {Array.from({ length: Math.ceil(filteredSales.length / itemsPerPage) }, (_, i) => (
                                                    <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                        <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
                                                    </li>
                                                ))}
                                                <li className={`page-item ${currentPage === Math.ceil(filteredSales.length / itemsPerPage) && 'disabled'}`}>
                                                    <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* {showSalesDetails && selectedSales && (
                                        <SalesDesc
                                            sales={selectedSales}
                                            onClose={handleBackToTable}
                                        />
                                    )} */}
                                    {selectedSales && !showSalesDetails && (
                                        <EditSales sales={selectedSales} onClose={handleEditSalesClose} onUpdate={fetchSales} />
                                    )}
                                    {isAddSalesModalOpen && <AddSales onClose={handleCloseSalesModal} onUpdate={fetchSales} />}
                                    <DeleteConfirmationModal
                                        isOpen={isDeleteModalOpen}
                                        itemName={deleteSales ? deleteSales.clientName : ""}
                                        onDelete={handleDeleteConfirmation}
                                        onClose={() => setIsDeleteModalOpen(false)}
                                        deleteReason={deleteReason}
                                        setDeleteReason={setDeleteReason}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SalesList;
















