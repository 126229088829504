import React, { useState, useEffect } from "react";
import axios from "axios";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from "../../components/sidebar/Sidebar";
import SearchBar from "../../components/sidebar/SearchBar";
import { ThreeDots } from 'react-loader-spinner'; // Spinner
import VendorReportPreview from "./VendorReportPreview";
import { debounce } from "lodash"; // Import debounce function

function VendorReport({ handleLogout, username }) {
    const [isLoading, setIsLoading] = useState(false);
    const [vendors, setVendors] = useState([]);
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(); // Initialize with current month (+1 since months are 0-indexed)
    const [selectedYear, setSelectedYear] = useState(); // Initialize with current year
    const [selectedVendor, setSelectedVendor] = useState(''); // For project filtering
    const [searchTerm, setSearchTerm] = useState(''); // State for search term
    const [grandTotals, setGrandTotals] = useState({}); // State for grand totals
    const [showSidebar, setShowSidebar] = useState(true); // State to control sidebar visibility
    const [showSearchBar, setShowSearchBar] = useState(true); // State to control search bar visibility
    const [detailsVisible, setDetailsVisible] = useState(false); // State for details modal
    const [selectedRecord, setSelectedRecord] = useState(null); // State for selected record

    useEffect(() => {
        fetchvendors();
    }, []);

    useEffect(() => {
        calculateTotals();
    }, [filteredRecords]);

    useEffect(() => {
        filterRecords();
    }, [selectedMonth, selectedYear, selectedVendor, searchTerm, vendors]);

    const fetchvendors = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/vendors`);
            setVendors(response.data);
        } catch (error) {
            console.error("Error fetching vendors:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const filterRecords = () => {
        // Filter the ledger entries based on the selected project, month, year, and search term
        const filtered = vendors.filter(record => {
            const date = new Date(record.createdAt);
            const isProjectMatch = selectedVendor ? record.projectId === parseInt(selectedVendor) : true;
            const isMonthMatch = selectedMonth ? date.getMonth() + 1 === selectedMonth : true; // +1 because JS months are 0-based
            const isYearMatch = selectedYear ? date.getFullYear() === selectedYear : true;
            const isSearchMatch = record.vendorName.toLowerCase().includes(searchTerm.toLowerCase());

            return isProjectMatch && isMonthMatch && isYearMatch && isSearchMatch;
        });

        setFilteredRecords(filtered);
    };

    const handleAllButtonClick = () => {
        // Reset filters
        setSelectedVendor('');
        setSelectedMonth('');
        setSelectedYear('');
        setSearchTerm(''); // Clear search term
        setFilteredRecords(vendors); // Show all records
    };

    const calculateTotals = () => {
        const totals = filteredRecords.reduce((acc, record) => {
            acc.totalProject += 1;
            acc.totalAmount += record.amount ? parseFloat(record.amount) : 0;
            return acc;
        }, {
            totalProject: 0,
            totalAmount: 0,
        });

        setGrandTotals(totals);
    };

    const handleDetails = () => {
        setSelectedRecord({
            date: new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
            status: filteredRecords.length > 0 ? filteredRecords[0].status : '',
            recordData: filteredRecords,
            selectedMonth,
            selectedYear
        });

        setDetailsVisible(true);
        setShowSidebar(false);
        setShowSearchBar(false);
    };

    const handleClosePreview = () => {
        setShowSidebar(true); // Show sidebar when closing preview
        setShowSearchBar(true); // Show search bar when closing preview
        setDetailsVisible(false); // Hide details toLowerCase
    };

    const handleSearchChange = debounce((event) => {
        setSearchTerm(event.target.value);
    }, 300);

    // Function to format the date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} `;
    };

    return (
        <div className='d-flex w-100 h-100 bg-white'>
            {showSidebar && <Sidebar />}
            <div className='w-100'>
                {showSearchBar && <SearchBar className="searchbarr" username={username} handleLogout={handleLogout} />}
                <div className="container-fluid">
                    <ToastContainer />
                    {detailsVisible ? (
                        <VendorReportPreview
                            record={selectedRecord}
                            onClose={handleClosePreview}
                        />
                    ) : (<div className="row">
                        <div className="col-xl-12">
                            <div style={{ borderRadius: "20px", border: "1px solid #003459" }} className='overflow-hidden'>
                                <div style={{background: "rgb(33,131,128)", background: "#00509d " }} className="row no-gutters align-items-center p-3">
                                    <div className="col">
                                        <div className="text-xs font-weight-bold text-white text-uppercase d-flex align-items-center justify-content-between" style={{ fontSize: '1.5rem' }}>
                                            <div className="nunito text-white">Vendor Report</div>
                                            <div className=" d-flex gap-3">
                                                <div className='d-flex align-items-center justify-content-center gap-2'>
                                                    <label className='nunito text-white p-0 m-0'>Search:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control button_details  "
                                                        placeholder="Search for vendors..."
                                                        aria-label="Search"
                                                        aria-describedby="basic-addon2"
                                                        onChange={handleSearchChange}
                                                    />
                                                </div>
                                                <div className="d-flex align-items-center justify-content-center gap-2">
                                                    <label className='nunito text-white p-0 m-0'>Filter:</label>
                                                    <select
                                                        className="button_details"
                                                        value={selectedMonth}
                                                        onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
                                                    >
                                                        <option value="">Select Month</option>
                                                        {Array.from({ length: 12 }, (_, i) => (
                                                            <option key={i} value={i + 1}>{new Date(2000, i).toLocaleString('default', { month: 'long' })}</option>
                                                        ))}
                                                    </select>
                                                    <select
                                                        className="button_details"
                                                        value={selectedYear}
                                                        onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                                                    >
                                                        <option value="">Select Year</option>
                                                        {Array.from({ length: 10 }, (_, i) => (
                                                            <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <button className="button_details" onClick={handleAllButtonClick}>All</button>
                                            </div>
                                            <button onClick={handleDetails} className="button_details">
                                                PDF
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <hr className='m-0 p-0' />
                                <div className=''>
                                    <div className="card-body">
                                        <div className="" style={{ maxHeight: "610px", overflowY: "auto" }}>
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <ThreeDots color="#00509d" height={80} width={80} />
                                                </div>
                                            ) : (
                                                <table className="table table-bordered" style={{ width: "100%" }}>
                                                    <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Vendor Name</th>
                                                            <th>Company Code</th>
                                                            <th>Contact Person</th>
                                                            <th>Mobile No.</th>
                                                            <th>Email ID</th>
                                                            <th>City</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredRecords.length === 0 ? (
                                                            <tr>
                                                                <td colSpan="5" className="text-center">No Ledger Entries.</td>
                                                            </tr>
                                                        ) : (
                                                            filteredRecords.map((vendor, index) => (
                                                                <tr key={index}>
                                                                    <td>{formatDate(vendor.createdAt)}</td>
                                                                    <td>{vendor.vendorName}</td>
                                                                    <td>{vendor.vendorCode}</td>
                                                                    <td>{vendor.contactPersonName1}</td>
                                                                    <td>{vendor.mobileNo1}</td>
                                                                    <td>{vendor.emailId1}</td>
                                                                    <td>{vendor.city}</td>
                                                                </tr>
                                                            ))
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th>Totals</th>
                                                            <th>{grandTotals.totalProject}</th>                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default VendorReport;
