import React, { useState, useEffect } from "react";
import axios from "axios";
import EditCourier from "./EditCourier";

const CourierDesc = ({ courier, onClose }) => {
    const [courierHistory, setCourierHistory] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {
        const fetchCourierHistory = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_LOCAL_URL}/api/courier/history/${courier.id}`
                );
                setCourierHistory(response.data);
            } catch (error) {
                console.error("Error fetching courier history:", error);
            }
        };

        fetchCourierHistory();
    }, [courier]);

    const handleEditCourier = () => {
        setIsEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
    };

    // Function to format the date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} `;
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = courierHistory.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const selectedServices = JSON.parse(courier.selectedServices || '[]').map(service => service.name).join(', ');

    return (
        <div>
            <div className="card-body p-4">
                <div className="row">
                    <div className="col-md-9">
                        <h4 className="title-detail font-bold" style={{ color: "black" }}>
                            Courier Name - {courier.courierCompanyName}
                        </h4>
                        <small>Created By - {courier.username || "-"}</small>
                    </div>
                    <div className="col-md-3">
                        <div className="p-2 barcode-inner">
                            <div className="assetbarcode d-flex flex-column gap-3">
                                <button onClick={onClose} className="btn btn-outline-primary">
                                    Back to Courier List
                                </button>
                                <button onClick={handleEditCourier} className="btn btn-outline-primary">
                                    Edit Courier
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a
                                    className="nav-link active show"
                                    id="details-tab"
                                    data-toggle="tab"
                                    href="#details"
                                    role="tab"
                                    aria-controls="details"
                                    aria-selected="true"
                                >
                                    Courier Details
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div
                                className="tab-pane fade active show"
                                id="details"
                                role="tabpanel"
                                aria-labelledby="details-tab"
                            >
                                <div className="row">
                                    <div className="col-md-12"  style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto", overflowX:"hidden" }}>
                                        <table className="table table-hover" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Courier Name:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assettype2">{courier.courierCompanyName || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Phone :</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetstatus">{courier.phone || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Address:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{courier.address || "-"}</p>
                                                    </td>
                                                </tr>
                                                {/* Add other courier details here */}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                            {/* Courier History */}
                            <div className="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Event</th>
                                                    <th>Date</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItems.map((entry) => (
                                                    <tr key={entry.event_id}>
                                                        <td>{entry.event}</td>
                                                        <td> {formatDate(entry.date)}</td>
                                                        <td>{entry.description}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {/* Pagination */}
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                            </li>
                                            {Array.from({ length: Math.ceil(courierHistory.length / itemsPerPage) || 1 }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(courierHistory.length / itemsPerPage) && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isEditModalOpen && <EditCourier
                courier={courier}
                onClose={handleCloseEditModal}
            />}
        </div>
    );
};

export default CourierDesc;
