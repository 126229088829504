import React, { useState, useEffect } from "react";
import axios from "axios";
import AddInquiry from "./AddInquiry";
import InquiryDesc from "./InquiryDesc";
import EditInquiry from './NextInquiry';
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import SearchBar from "../../components/sidebar/SearchBar";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThreeDots } from 'react-loader-spinner'; // Spinner
import { debounce } from "lodash"; // Import debounce function

function InquiryList({ handleLogout, username }) {
    const [isLoading, setIsLoading] = useState(false);
    const [inquiries, setInquiries] = useState([]);
    const [employees, setEmployees] = useState([]); // New state for employees
    const [selectedInquiry, setSelectedInquiry] = useState(null);
    const [showInquiryDetails, setShowInquiryDetails] = useState(false);
    const [isAddInquiryModalOpen, setIsAddInquiryModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editInquiry, setEditInquiry] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [deleteInquiry, setDeleteInquiry] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");
    const [searchTerm, setSearchTerm] = useState(""); // search term
    const [selectedEmployee, setSelectedEmployee] = useState(""); // selected employee

    useEffect(() => {
        fetchInquiries();
        fetchEmployees(); // Fetch employees when component mounts
    }, []);

    const fetchInquiries = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/inquiries`);
            const sortedInquiries = response.data.filter(item => item.status === "neutral");
            setInquiries(sortedInquiries);
        } catch (error) {
            console.error("Error fetching inquiries:", error);
        }
    };

    const fetchEmployees = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
            setEmployees(response.data);
        } catch (error) {
            console.error("Error fetching employees:", error);
        }
    };

    const handleAddInquiry = () => {
        setIsAddInquiryModalOpen(true);
    };

    const handleCloseInquiryModal = () => {
        setIsAddInquiryModalOpen(false);
        setIsEditModalOpen(false);
    };

    const handleInquiryDetails = (inquiry) => {
        setSelectedInquiry(inquiry);
        setShowInquiryDetails(true);
    };

    const handleEditInquiryClick = (inquiry) => {
        setEditInquiry(inquiry);
        setSelectedInquiry(inquiry);
        setIsEditModalOpen(true);
    };

    const handleEditInquiryClose = () => {
        setSelectedInquiry(null);
    };

    const handleBackToTable = () => {
        setSelectedInquiry(null);
        setShowInquiryDetails(false);
    };

    const handleDeleteInquiry = (inquiry) => {
        setDeleteInquiry(inquiry);
        setIsDeleteModalOpen(true);
    };

    const handleUpdateInquiry = async (updatedInquiry) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/inquiries/${updatedInquiry.id}`, updatedInquiry);
            console.log("Inquiry updated:", response.data);
            const updatedInquiryList = inquiries.map(inq => (inq.id === updatedInquiry.id ? response.data : inq));
            const sortedInquiries = updatedInquiryList.sort((a, b) => b.id - a.id);
            setInquiries(sortedInquiries);
        } catch (error) {
            console.error("Error updating inquiry:", error);
        }
    };

    const handleDeleteConfirmation = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/inquiries/${deleteInquiry.id}`);

            const deletedInquiry = { ...deleteInquiry, reason: deleteReason };
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedInquiry);

            setInquiries((prevInquiries) =>
                prevInquiries.filter((inq) => inq.id !== deleteInquiry.id)
            );
            setIsDeleteModalOpen(false);

            console.log("Inquiry deleted successfully");
        } catch (error) {
            console.error("Error deleting inquiry:", error);
        }
    };

    const handleUpdateInquiryList = async (newInquiry) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/inquiries`, newInquiry);
            const newInquiryEntry = response.data;
            setInquiries((prevInquiries) => [newInquiryEntry, ...prevInquiries]);
            toast.success("Inquiry entry added successfully");
        } catch (error) {
            console.error("Error adding inquiry:", error);
        }
    };

    

    const onUpdateinquery = () => {
        toast.success("Data Uploaded Successfully")
        fetchInquiries();
    };

    // Search 
    const handleSearchChange = debounce((event) => {
        setSearchTerm(event.target.value);
    }, 300);

    // Filter inquiries based on the search term and selected employee
    const filteredInquiries = inquiries.filter(inquiry =>
        inquiry.customerName.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (selectedEmployee ? inquiry.callAttendExecutiveName === selectedEmployee : true)
    );


    // Function to format the date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} `;
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredInquiries.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className='d-flex w-100 h-100 bg-white'>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    {showInquiryDetails && selectedInquiry ? (
                        <InquiryDesc inquiry={selectedInquiry} onClose={handleBackToTable} />
                    ) : (
                        <div className="row">
                            <div className="col-xl-12">
                                 <div style={{ borderRadius: "20px", border: "1px solid #003459" }} className='overflow-hidden'>
                                    <div style={{ background: "rgb(33,131,128)", background: "#00509d " }} className="row no-gutters align-items-center p-3">
                                        <div className="col">
                                            <div className="text-xs font-weight-bold text-white text-uppercase d-flex align-items-center justify-content-between" style={{ fontSize: '1.5rem' }}>
                                                <div className="nunito text-white">Inquiry List</div>
                                                <div className=" d-flex gap-3">
                                                    <div className='d-flex align-items-center justify-content-center gap-2'>
                                                        <label className='nunito text-white p-0 m-0'>Filter:-</label>
                                                        <select
                                                            className="button_details overflow-hidden"
                                                            value={selectedEmployee}
                                                            onChange={(e) => setSelectedEmployee(e.target.value)}

                                                        >
                                                            <option value="">Executive Names</option>
                                                            {employees.map((employee) => (
                                                                <option key={employee.id} value={employee.ename}>
                                                                    {employee.ename}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-center gap-2">
                                                        <input
                                                            type="text"
                                                            className="form-control button_details py-0"
                                                            placeholder="Search for clients..."
                                                            aria-label="Search"
                                                            aria-describedby="basic-addon2"
                                                            onChange={handleSearchChange}
                                                        />
                                                        <div className="input-group-append">
                                                            <button className="button_details" type="button">
                                                                <i className="fas fa-search fa-sm"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button onClick={handleAddInquiry} className="button_details"><i className="fa fa-plus"></i> Add New Inquiry
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='m-0 p-0' />
                                    <div className=''>
                                        <div className="card-body">
                                            <div className="" style={{ maxHeight: "610px", overflowY: "auto" }}>
                                                {isLoading ? (
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <ThreeDots color="#00509d" height={80} width={80} />
                                                    </div>
                                                ) : (
                                                    <table className="table table-bordered" style={{ width: "100%" }}>
                                                        <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                            <tr>
                                                                <th>Customer Name</th>
                                                                <th>Call Date</th>
                                                                <th>Mobile No</th>
                                                                <th>Executive Name</th>
                                                                <th>Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <style>
                                                                {`.hyperlink:hover {color: blue;}`}
                                                            </style>
                                                            {currentItems.length === 0 ? (
                                                                <tr>
                                                                    <td colSpan="12" className="text-center">No Inquiry Entries.</td>
                                                                </tr>
                                                            ) : (
                                                                currentItems.map((inquiry, index) => (
                                                                    <tr key={index}>
                                                                        <td className='hyperlink' style={{ cursor: "pointer" }} onClick={() => handleInquiryDetails(inquiry)}>{inquiry.customerName}</td>
                                                                        <td> {formatDate(inquiry.callDate)}</td>
                                                                        <td>{inquiry.mobileNo}</td>
                                                                        <td>{inquiry.callAttendExecutiveName}</td>
                                                                        <td className="text-end">&#x20B9;{inquiry.amount != null ? inquiry.amount.toFixed(2) : '0.00'}</td>
                                                                    </tr>
                                                                ))
                                                            )}
                                                        </tbody>
                                                    </table>
                                                )}
                                            </div>
                                            <ul className="pagination mt-2">
                                                <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                                </li>
                                                {Array.from({ length: Math.ceil(filteredInquiries.length / itemsPerPage) }, (_, i) => (
                                                    <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                        <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                                                    </li>
                                                ))}
                                                <li className={`page-item ${currentPage === Math.ceil(filteredInquiries.length / itemsPerPage) && 'disabled'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* {showInquiryDetails && selectedInquiry && (
                                    <InquiryDesc
                                        inquiry={selectedInquiry}
                                        onClose={handleBackToTable}
                                    />
                                )} */}
                                    {selectedInquiry && !showInquiryDetails && (
                                        <EditInquiry inquiry={selectedInquiry} onClose={handleEditInquiryClose} onUpdate={handleUpdateInquiry} />
                                    )}
                                    {isAddInquiryModalOpen && <AddInquiry onClose={handleCloseInquiryModal} onUpdate={onUpdateinquery} />}
                                    <DeleteConfirmationModal
                                        isOpen={isDeleteModalOpen}
                                        itemName={deleteInquiry ? deleteInquiry.clientName : ""}
                                        onDelete={handleDeleteConfirmation}
                                        onClose={() => setIsDeleteModalOpen(false)}
                                        deleteReason={deleteReason}
                                        setDeleteReason={setDeleteReason}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default InquiryList;








































